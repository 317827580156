export const country = {
	status: "OK",
	"status-code": 200,
	version: "1.0",
	access: "public",
	data: [
		{
			country: "Afghanistan",
			dial_code: "+93",
			flag: "flags/Afghanistan.png",
			code: "AF",
			continent: "Asia",
		},
		{
			country: "Aland Islands",
			dial_code: "+358",
			flag: "flags/Aland.png",
			code: "AX",
			continent: "Europe",
		},
		{
			country: "Albania",
			dial_code: "+355",
			flag: "flags/Albania.png",
			code: "AL",
			continent: "Europe",
		},
		{
			country: "Algeria",
			dial_code: "+213",
			flag: "flags/Algeria.png",
			code: "DZ",
			continent: "Africa",
		},
		{
			country: "American samoa",
			dial_code: "+1684",
			flag: "flags/American_Samoa.png",
			code: "AS",
			continent: "Oceania",
		},
		{
			country: "Andorra",
			dial_code: "+376",
			flag: "flags/Andorra.png",
			code: "AD",
			continent: "Europe",
		},
		{
			country: "Angola",
			dial_code: "+244",
			flag: "flags/Angola.png",
			code: "AO",
			continent: "Africa",
		},
		{
			country: "Anguilla",
			dial_code: "+1264",
			flag: "flags/Anguilla.png",
			code: "AI",
			continent: "North America",
		},
		{
			country: "Antigua and Barbuda",
			dial_code: "+1268",
			flag: "flags/Antigua_and_Barbuda.png",
			code: "AG",
			continent: "North America",
		},
		{
			country: "Argentina",
			dial_code: "+54",
			flag: "flags/Argentina.png",
			code: "AR",
			continent: "South America",
		},
		{
			country: "Armenia",
			dial_code: "+374",
			flag: "flags/Armenia.png",
			code: "AM",
			continent: "Asia",
		},
		{
			country: "Aruba",
			dial_code: "+297",
			flag: "flags/Aruba.png",
			code: "AW",
			continent: "North America",
		},
		{
			country: "Australia",
			dial_code: "+61",
			flag: "flags/Australia.png",
			code: "AU",
			continent: "Oceania",
		},
		{
			country: "Austria",
			dial_code: "+43",
			flag: "flags/Austria.png",
			code: "AT",
			continent: "Europe",
		},
		{
			country: "Azerbaijan",
			dial_code: "+994",
			flag: "flags/Azerbaijan.png",
			code: "AZ",
			continent: "Asia",
		},
		{
			country: "Bahamas",
			dial_code: "+1242",
			flag: "flags/Bahamas.png",
			code: "BS",
			continent: "North America",
		},
		{
			country: "Bahrain",
			dial_code: "+973",
			flag: "flags/Bahrain.png",
			code: "BH",
			continent: "Asia",
		},
		{
			country: "Bangladesh",
			dial_code: "+880",
			flag: "flags/Bangladesh.png",
			code: "BD",
			continent: "Asia",
		},
		{
			country: "Barbados",
			dial_code: "+1",
			flag: "flags/Barbados.png",
			code: "BB",
			continent: "North America",
		},
		{
			country: "Belarus",
			dial_code: "+375",
			flag: "flags/Belarus.png",
			code: "BY",
			continent: "Europe",
		},
		{
			country: "Belgium",
			dial_code: "+32",
			flag: "flags/Belgium.png",
			code: "BE",
			continent: "Europe",
		},
		{
			country: "Belize",
			dial_code: "+501",
			flag: "flags/Belize.png",
			code: "BZ",
			continent: "North America",
		},
		{
			country: "Benin",
			dial_code: "+229",
			flag: "flags/Benin.png",
			code: "BJ",
			continent: "Africa",
		},
		{
			country: "Bermuda",
			dial_code: "+1441",
			flag: "flags/Bermuda.png",
			code: "BM",
			continent: "North America",
		},
		{
			country: "Bhutan",
			dial_code: "+975",
			flag: "flags/Bhutan.png",
			code: "BT",
			continent: "Asia",
		},
		{
			country: "Bolivia, Plurinational State of",
			dial_code: "+591",
			flag: "flags/Bolivia.png",
			code: "BO",
			continent: "South America",
		},
		{
			country: "Bosnia and Herzegovina",
			dial_code: "+387",
			flag: "flags/Bosnia.png",
			code: "BA",
			continent: "Europe",
		},
		{
			country: "Botswana",
			dial_code: "+267",
			flag: "flags/Botswana.png",
			code: "BW",
			continent: "Africa",
		},
		{
			country: "Brazil",
			dial_code: "+55",
			flag: "flags/Brazil.png",
			code: "BR",
			continent: "South America",
		},
		{
			country: "British Indian Ocean Territory",
			dial_code: "+246",
			flag: "flags/British_Virgin_Islands.png",
			code: "IO",
			continent: "Asia",
		},
		{
			country: "Brunei Darussalam",
			dial_code: "+673",
			flag: "flags/Brunei.png",
			code: "BN",
			continent: "Asia",
		},
		{
			country: "Bulgaria",
			dial_code: "+359",
			flag: "flags/Bulgaria.png",
			code: "BG",
			continent: "Europe",
		},
		{
			country: "Burkina Faso",
			dial_code: "+226",
			flag: "flags/Burkina_Faso.png",
			code: "BF",
			continent: "Africa",
		},
		{
			country: "Burundi",
			dial_code: "+257",
			flag: "flags/Burundi.png",
			code: "BI",
			continent: "Africa",
		},
		{
			country: "Cambodia",
			dial_code: "+855",
			flag: "flags/Cambodia.png",
			code: "KH",
			continent: "Asia",
		},
		{
			country: "Cameroon",
			dial_code: "+237",
			flag: "flags/Cameroon.png",
			code: "CM",
			continent: "Africa",
		},
		{
			country: "Canada",
			dial_code: "+1",
			flag: "flags/Canada.png",
			code: "CA",
			continent: "North America",
		},
		{
			country: "Cape Verde",
			dial_code: "+238",
			flag: "flags/Cape_Verde.png",
			code: "CV",
			continent: "Africa",
		},
		{
			country: "Cayman Islands",
			dial_code: "+ 345",
			flag: "flags/Cayman_Islands.png",
			code: "KY",
			continent: "North America",
		},
		{
			country: "Central African Republic",
			dial_code: "+236",
			flag: "flags/Central_African_Republic.png",
			code: "CF",
			continent: "Africa",
		},
		{
			country: "Chad",
			dial_code: "+235",
			flag: "flags/Chad.png",
			code: "TD",
			continent: "Africa",
		},
		{
			country: "Chile",
			dial_code: "+56",
			flag: "flags/Chile.png",
			code: "CL",
			continent: "South America",
		},
		{
			country: "China",
			dial_code: "+86",
			flag: "flags/China.png",
			code: "CN",
			continent: "Asia",
		},
		{
			country: "Christmas Island",
			dial_code: "+61",
			flag: "flags/Christmas_Island.png",
			code: "CX",
			continent: "Oceania",
		},
		{
			country: "Cocos (Keeling) Islands",
			dial_code: "+61",
			flag: "flags/Cocos.png",
			code: "CC",
			continent: "Oceania",
		},
		{
			country: "Colombia",
			dial_code: "+57",
			flag: "flags/Colombia.png",
			code: "CO",
			continent: "South America",
		},
		{
			country: "Comoros",
			dial_code: "+269",
			flag: "flags/Comoros.png",
			code: "KM",
			continent: "Africa",
		},
		{
			country: "Congo",
			dial_code: "+242",
			flag: "flags/Congo.jpg",
			code: "CG",
			continent: "Africa",
		},
		{
			country: "Cook Islands",
			dial_code: "+682",
			flag: "flags/Cook_Islands.png",
			code: "CK",
			continent: "Oceania",
		},
		{
			country: "Costa Rica",
			dial_code: "+506",
			flag: "flags/Costa_Rica.png",
			code: "CR",
			continent: "North America",
		},
		{
			country: "Cote d'Ivoire",
			dial_code: "+225",
			flag: "flags/Côte_d'Ivoire.png",
			code: "CI",
			continent: "Africa",
		},
		{
			country: "Croatia",
			dial_code: "+385",
			flag: "flags/Croatia.png",
			code: "HR",
			continent: "Europe",
		},
		{
			country: "Cuba",
			dial_code: "+53",
			flag: "flags/Cuba.png",
			code: "CU",
			continent: "North America",
		},
		{
			country: "Cyprus",
			dial_code: "+357",
			flag: "flags/Cyprus.png",
			code: "CY",
			continent: "Europe",
		},
		{
			country: "Czech Republic",
			dial_code: "+420",
			flag: "flags/Czech_Republic.png",
			code: "CZ",
			continent: "Europe",
		},
		{
			country: "Democratic republic of congo",
			dial_code: "+243",
			flag: "flags/Democratic_Republic_of_the_Congo.png",
			code: "CD",
			continent: "Africa",
		},
		{
			country: "Denmark",
			dial_code: "+45",
			flag: "flags/Denmark.png",
			code: "DK",
			continent: "Europe",
		},
		{
			country: "Djibouti",
			dial_code: "+253",
			flag: "flags/Djibouti.png",
			code: "DJ",
			continent: "Africa",
		},
		{
			country: "Dominica",
			dial_code: "+1767",
			flag: "flags/Dominica.png",
			code: "DM",
			continent: "North America",
		},
		{
			country: "Dominican Republic",
			dial_code: "+1849",
			flag: "flags/Dominican_Republic.png",
			code: "DO",
			continent: "North America",
		},
		{
			country: "Ecuador",
			dial_code: "+593",
			flag: "flags/Ecuador.png",
			code: "EC",
			continent: "South America",
		},
		{
			country: "Egypt",
			dial_code: "+20",
			flag: "flags/Egypt.png",
			code: "EG",
			continent: "Africa",
		},
		{
			country: "El Salvador",
			dial_code: "+503",
			flag: "flags/El_Salvador.png",
			code: "SV",
			continent: "North America",
		},
		{
			country: "Equatorial Guinea",
			dial_code: "+240",
			flag: "flags/Equatorial_Guinea.png",
			code: "GQ",
			continent: "Africa",
		},
		{
			country: "Eritrea",
			dial_code: "+291",
			flag: "flags/Eritrea.png",
			code: "ER",
			continent: "Africa",
		},
		{
			country: "Estonia",
			dial_code: "+372",
			flag: "flags/Estonia.png",
			code: "EE",
			continent: "Europe",
		},
		{
			country: "Ethiopia",
			dial_code: "+251",
			flag: "flags/Ethiopia.png",
			code: "ET",
			continent: "Africa",
		},
		{
			country: "Falkland Islands (Malvinas)",
			dial_code: "+500",
			flag: "flags/Falkland_Islands.png",
			code: "FK",
			continent: "South America",
		},
		{
			country: "Faroe Islands",
			dial_code: "+298",
			flag: "flags/Faroe_Islands.png",
			code: "FO",
			continent: "Europe",
		},
		{
			country: "Fiji",
			dial_code: "+679",
			flag: "flags/Fiji.png",
			code: "FJ",
			continent: "Oceania",
		},
		{
			country: "Finland",
			dial_code: "+358",
			flag: "flags/Finland.png",
			code: "FI",
			continent: "Europe",
		},
		{
			country: "France",
			dial_code: "+33",
			flag: "flags/France.png",
			code: "FR",
			continent: "Europe",
		},
		{
			country: "French Guiana",
			dial_code: "+594",
			flag: "flags/French.png",
			code: "GF",
			continent: "South America",
		},
		{
			country: "French Polynesia",
			dial_code: "+689",
			flag: "flags/French_Polynesia.png",
			code: "PF",
			continent: "Oceania",
		},
		{
			country: "Gabon",
			dial_code: "+241",
			flag: "flags/Gabon.png",
			code: "GA",
			continent: "Africa",
		},
		{
			country: "Gambia",
			dial_code: "+220",
			flag: "flags/Gambia.png",
			code: "GM",
			continent: "Africa",
		},
		{
			country: "Georgia",
			dial_code: "+995",
			flag: "flags/Georgia.png",
			code: "GE",
			continent: "Asia",
		},
		{
			country: "Germany",
			dial_code: "+49",
			flag: "flags/Germany.png",
			code: "DE",
			continent: "Europe",
		},
		{
			country: "Ghana",
			dial_code: "+233",
			flag: "flags/Ghana.png",
			code: "GH",
			continent: "Africa",
		},
		{
			country: "Gibraltar",
			dial_code: "+350",
			flag: "flags/Gibraltar.png",
			code: "GI",
			continent: "Europe",
		},
		{
			country: "Greece",
			dial_code: "+30",
			flag: "flags/Greece.png",
			code: "EL",
			continent: "Europe",
		},
		{
			country: "Greenland",
			dial_code: "+299",
			flag: "flags/Greenland.png",
			code: "GL",
			continent: "North America",
		},
		{
			country: "Grenada",
			dial_code: "+1473",
			flag: "flags/Grenada.png",
			code: "GD",
			continent: "North America",
		},
		{
			country: "Guadeloupe",
			dial_code: "+590",
			flag: "flags/Guadeloupe.jpg",
			code: "GP",
			continent: "North America",
		},
		{
			country: "Guam",
			dial_code: "+1671",
			flag: "flags/Guam.png",
			code: "GU",
			continent: "Oceania",
		},
		{
			country: "Guatemala",
			dial_code: "+502",
			flag: "flags/Guatemala.png",
			code: "GT",
			continent: "North America",
		},
		{
			country: "Guernsey",
			dial_code: "+44",
			flag: "flags/Guernsey.png",
			code: "GG",
			continent: "Europe",
		},
		{
			country: "Guinea",
			dial_code: "+224",
			flag: "flags/Guinea.png",
			code: "GN",
			continent: "Africa",
		},
		{
			country: "Guinea-Bissau",
			dial_code: "+245",
			flag: "flags/Guinea_Bissau.png",
			code: "GW",
			continent: "Africa",
		},
		{
			country: "Guyana",
			dial_code: "+595",
			flag: "flags/Guyana.png",
			code: "GY",
			continent: "South America",
		},
		{
			country: "Haiti",
			dial_code: "+509",
			flag: "flags/Haiti.png",
			code: "HT",
			continent: "North America",
		},
		{
			country: "Holy See (Vatican City State)",
			dial_code: "+379",
			flag: "flags/Holy.png",
			code: "VA",
			continent: "Europe",
		},
		{
			country: "Honduras",
			dial_code: "+504",
			flag: "flags/Honduras.png",
			code: "HN",
			continent: "North America",
		},
		{
			country: "Hong Kong",
			dial_code: "+852",
			flag: "flags/Hong_Kong.png",
			code: "HK",
			continent: "Asia",
		},
		{
			country: "Hungary",
			dial_code: "+36",
			flag: "flags/Hungary.png",
			code: "HU",
			continent: "Europe",
		},
		{
			country: "Iceland",
			dial_code: "+354",
			flag: "flags/Iceland.png",
			code: "IS",
			continent: "Europe",
		},
		{
			country: "India",
			dial_code: "+91",
			flag: "flags/India.png",
			code: "IN",
			continent: "Asia",
		},
		{
			country: "Indonesia",
			dial_code: "+62",
			flag: "flags/Indonesia.png",
			code: "ID",
			continent: "Asia",
		},
		{
			country: "Iran, Islamic Republic of Persian Gulf",
			dial_code: "+98",
			flag: "flags/Iran.png",
			code: "IR",
			continent: "Asia",
		},
		{
			country: "Iraq",
			dial_code: "+964",
			flag: "flags/Iraq.png",
			code: "IQ",
			continent: "Asia",
		},
		{
			country: "Ireland",
			dial_code: "+353",
			flag: "flags/Ireland.png",
			code: "IE",
			continent: "Europe",
		},
		{
			country: "Isle of Man",
			dial_code: "+44",
			flag: "flags/Isle.png",
			code: "IM",
			continent: "Europe",
		},
		{
			country: "Israel",
			dial_code: "+972",
			flag: "flags/Israel.png",
			code: "IL",
			continent: "Asia",
		},
		{
			country: "Italy",
			dial_code: "+39",
			flag: "flags/Italy.png",
			code: "IT",
			continent: "Europe",
		},
		{
			country: "Jamaica",
			dial_code: "+1876",
			flag: "flags/Jamaica.png",
			code: "JM",
			continent: "North America",
		},
		{
			country: "Japan",
			dial_code: "+81",
			flag: "flags/Japan.png",
			code: "JP",
			continent: "Asia",
		},
		{
			country: "Jersey",
			dial_code: "+44",
			flag: "flags/Jersey.png",
			code: "JE",
			continent: "Europe",
		},
		{
			country: "Jordan",
			dial_code: "+962",
			flag: "flags/Jordan.png",
			code: "JO",
			continent: "Asia",
		},
		{
			country: "Kazakhstan",
			dial_code: "+77",
			flag: "flags/Kazakhstan.png",
			code: "KZ",
			continent: "Asia",
		},
		{
			country: "Kenya",
			dial_code: "+254",
			flag: "flags/Kenya.png",
			code: "KE",
			continent: "Africa",
		},
		{
			country: "Kiribati",
			dial_code: "+686",
			flag: "flags/Kiribati.png",
			code: "KI",
			continent: "Oceania",
		},
		{
			country: "Korea",
			dial_code: "+82",
			flag: "flags/South_Korea.png",
			code: "KR",
			continent: "Asia",
		},
		{
			country: "Kuwait",
			dial_code: "+965",
			flag: "flags/Kuwait.png",
			code: "KW",
			continent: "Asia",
		},
		{
			country: "Kyrgyzstan",
			dial_code: "+996",
			flag: "flags/Kyrgyzstan.png",
			code: "KG",
			continent: "Asia",
		},
		{
			country: "Laos",
			dial_code: "+856",
			flag: "flags/Laos.png",
			code: "LA",
			continent: "Asia",
		},
		{
			country: "Latvia",
			dial_code: "+371",
			flag: "flags/Latvia.png",
			code: "LV",
			continent: "Europe",
		},
		{
			country: "Lebanon",
			dial_code: "+961",
			flag: "flags/Lebanon.png",
			code: "LB",
			continent: "Asia",
		},
		{
			country: "Lesotho",
			dial_code: "+266",
			flag: "flags/Lesotho.png",
			code: "LS",
			continent: "Africa",
		},
		{
			country: "Liberia",
			dial_code: "+231",
			flag: "flags/Liberia.png",
			code: "LR",
			continent: "Africa",
		},
		{
			country: "Libyan Arab Jamahiriya",
			dial_code: "+218",
			flag: "flags/Libya.png",
			code: "LY",
			continent: "Africa",
		},
		{
			country: "Liechtenstein",
			dial_code: "+423",
			flag: "flags/Liechtenstein.png",
			code: "LI",
			continent: "Europe",
		},
		{
			country: "Lithuania",
			dial_code: "+370",
			flag: "flags/Lithuania.png",
			code: "LT",
			continent: "Europe",
		},
		{
			country: "Luxembourg",
			dial_code: "+352",
			flag: "flags/Luxembourg.png",
			code: "LU",
			continent: "Europe",
		},
		{
			country: "Macao",
			dial_code: "+853",
			flag: "flags/Macao.png",
			code: "MO",
			continent: "Asia",
		},
		{
			country: "Macedonia",
			dial_code: "+389",
			flag: "flags/Macedonia.png",
			code: "MK",
			continent: "Europe",
		},
		{
			country: "Madagascar",
			dial_code: "+261",
			flag: "flags/Madagascar.png",
			code: "MG",
			continent: "Africa",
		},
		{
			country: "Malawi",
			dial_code: "+265",
			flag: "flags/Malawi.png",
			code: "MW",
			continent: "Africa",
		},
		{
			country: "Malaysia",
			dial_code: "+60",
			flag: "flags/Malaysia.png",
			code: "MY",
			continent: "Asia",
		},
		{
			country: "Maldives",
			dial_code: "+960",
			flag: "flags/Maldives.png",
			code: "MV",
			continent: "Asia",
		},
		{
			country: "Mali",
			dial_code: "+223",
			flag: "flags/Mali.png",
			code: "ML",
			continent: "Africa",
		},
		{
			country: "Malta",
			dial_code: "+356",
			flag: "flags/Malta.png",
			code: "MT",
			continent: "Europe",
		},
		{
			country: "Marshall Islands",
			dial_code: "+692",
			flag: "flags/Marshall_Islands.png",
			code: "MH",
			continent: "Oceania",
		},
		{
			country: "Martinique",
			dial_code: "+596",
			flag: "flags/Martinique.png",
			code: "MQ",
			continent: "North America",
		},
		{
			country: "Mauritania",
			dial_code: "+222",
			flag: "flags/Mauritania.png",
			code: "MR",
			continent: "Africa",
		},
		{
			country: "Mauritius",
			dial_code: "+230",
			flag: "flags/Mauritius.png",
			code: "MU",
			continent: "Africa",
		},
		{
			country: "Mayotte",
			dial_code: "+262",
			flag: "flags/Mayotte.png",
			code: "YT",
			continent: "Africa",
		},
		{
			country: "Mexico",
			dial_code: "+52",
			flag: "flags/Mexico.png",
			code: "MX",
			continent: "North America",
		},
		{
			country: "Micronesia",
			dial_code: "+691",
			flag: "flags/Micronesia.png",
			code: "FM",
			continent: "Oceania",
		},
		{
			country: "Moldova",
			dial_code: "+373",
			flag: "flags/Moldova.png",
			code: "MD",
			continent: "Europe",
		},
		{
			country: "Monaco",
			dial_code: "+377",
			flag: "flags/Monaco.png",
			code: "MC",
			continent: "Europe",
		},
		{
			country: "Mongolia",
			dial_code: "+976",
			flag: "flags/Mongolia.png",
			code: "MN",
			continent: "Asia",
		},
		{
			country: "Montenegro",
			dial_code: "+382",
			flag: "flags/Montenegro.png",
			code: "ME",
			continent: "Europe",
		},
		{
			country: "Montserrat",
			dial_code: "+1664",
			flag: "flags/Montserrat.png",
			code: "MS",
			continent: "North America",
		},
		{
			country: "Morocco",
			dial_code: "+212",
			flag: "flags/Morocco.png",
			code: "MA",
			continent: "Africa",
		},
		{
			country: "Mozambique",
			dial_code: "+258",
			flag: "flags/Mozambique.png",
			code: "MZ",
			continent: "Africa",
		},
		{
			country: "Myanmar",
			dial_code: "+95",
			flag: "flags/Myanmar.png",
			code: "MM",
			continent: "Asia",
		},
		{
			country: "Namibia",
			dial_code: "+264",
			flag: "flags/Namibia.png",
			code: "NA",
			continent: "Africa",
		},
		{
			country: "Nauru",
			dial_code: "+674",
			flag: "flags/Nauru.png",
			code: "NR",
			continent: "Oceania",
		},
		{
			country: "Nepal",
			dial_code: "+977",
			flag: "flags/Nepal.png",
			code: "NP",
			continent: "Asia",
		},
		{
			country: "Netherlands",
			dial_code: "+31",
			flag: "flags/Netherlands.png",
			code: "NL",
			continent: "Europe",
		},
		{
			country: "Netherlands Antilles",
			dial_code: "+599",
			flag: "flags/Netherlands_Antilles.png",
			code: "AN",
			continent: "North America",
		},
		{
			country: "New Caledonia",
			dial_code: "+687",
			flag: "flags/New_Caledonia.png",
			code: "NC",
			continent: "Oceania",
		},
		{
			country: "New Zealand",
			dial_code: "+64",
			flag: "flags/New_Zealand.png",
			code: "NZ",
			continent: "Oceania",
		},
		{
			country: "Nicaragua",
			dial_code: "+505",
			flag: "flags/Nicaragua.png",
			code: "NI",
			continent: "North America",
		},
		{
			country: "Niger",
			dial_code: "+227",
			flag: "flags/Niger.png",
			code: "NE",
			continent: "Africa",
		},
		{
			country: "Nigeria",
			dial_code: "+234",
			flag: "flags/Nigeria.png",
			code: "NG",
			continent: "Africa",
		},
		{
			country: "Niue",
			dial_code: "+683",
			flag: "flags/Niue.png",
			code: "NU",
			continent: "Oceania",
		},
		{
			country: "Norfolk Island",
			dial_code: "+672",
			flag: "flags/Norfolk_Island.png",
			code: "NF",
			continent: "Oceania",
		},
		{
			country: "Northern Mariana Islands",
			dial_code: "+1670",
			flag: "flags/North_Korea.png",
			code: "MP",
			continent: "Oceania",
		},
		{
			country: "Norway",
			dial_code: "+47",
			flag: "flags/Norway.png",
			code: "NO",
			continent: "Europe",
		},
		{
			country: "Oman",
			dial_code: "+968",
			flag: "flags/Oman.png",
			code: "OM",
			continent: "Asia",
		},
		{
			country: "Pakistan",
			dial_code: "+92",
			flag: "flags/Pakistan.png",
			code: "PK",
			continent: "Asia",
		},
		{
			country: "Palau",
			dial_code: "+680",
			flag: "flags/Palau.png",
			code: "PW",
			continent: "Oceania",
		},
		{
			country: "Palestinian Territory, Occupied",
			dial_code: "+970",
			flag: "flags/Palestinian.png",
			code: "PS",
			continent: "Asia",
		},
		{
			country: "Panama",
			dial_code: "+507",
			flag: "flags/Panama.png",
			code: "PA",
			continent: "North America",
		},
		{
			country: "Papua New Guinea",
			dial_code: "+675",
			flag: "flags/Papua_New_Guinea.png",
			code: "PG",
			continent: "Oceania",
		},
		{
			country: "Paraguay",
			dial_code: "+595",
			flag: "flags/Paraguay.png",
			code: "PY",
			continent: "South America",
		},
		{
			country: "Peru",
			dial_code: "+51",
			flag: "flags/Peru.png",
			code: "PE",
			continent: "South America",
		},
		{
			country: "Philippines",
			dial_code: "+63",
			flag: "flags/Philippines.png",
			code: "PH",
			continent: "Asia",
		},
		{
			country: "Pitcairn",
			dial_code: "+872",
			flag: "flags/Pitcairn.png",
			code: "PN",
			continent: "Oceania",
		},
		{
			country: "Poland",
			dial_code: "+48",
			flag: "flags/Poland.png",
			code: "PL",
			continent: "Europe",
		},
		{
			country: "Portugal",
			dial_code: "+351",
			flag: "flags/Portugal.png",
			code: "PT",
			continent: "Europe",
		},
		{
			country: "Puerto Rico",
			dial_code: "+1939",
			flag: "flags/Puerto_Rico.png",
			code: "PR",
			continent: "North America",
		},
		{
			country: "Qatar",
			dial_code: "+974",
			flag: "flags/Qatar.png",
			code: "QA",
			continent: "Asia",
		},
		{
			country: "Romania",
			dial_code: "+40",
			flag: "flags/Romania.png",
			code: "RO",
			continent: "Europe",
		},
		{
			country: "Russia",
			dial_code: "+7",
			flag: "flags/Russian_Federation.png",
			code: "RU",
			continent: "Europe/Asia",
		},
		{
			country: "Rwanda",
			dial_code: "+250",
			flag: "flags/Rwanda.png",
			code: "RW",
			continent: "Africa",
		},
		{
			country: "Saint lucia",
			dial_code: "+1",
			flag: "flags/Saint_Lucia.png",
			code: "LC",
			continent: "North America",
		},
		{
			country: "San marino",
			dial_code: "+378",
			flag: "flags/San_Marino.png",
			code: "SM",
			continent: "Europe",
		},
		{
			country: "Saudi arabia",
			dial_code: "+966",
			flag: "flags/Saudi_Arabia.png",
			code: "SA",
			continent: "Asia",
		},
		{
			country: "Senegal",
			dial_code: "+221",
			flag: "flags/Senegal.png",
			code: "SN",
			continent: "Africa",
		},
		{
			country: "Seychelles",
			dial_code: "+248",
			flag: "flags/Seychelles.png",
			code: "SC",
			continent: "Africa",
		},
		{
			country: "Sierra leone",
			dial_code: "+232",
			flag: "flags/Sierra_Leone.png",
			code: "SL",
			continent: "Africa",
		},
		{
			country: "Singapore",
			dial_code: "+65",
			flag: "flags/Singapore.png",
			code: "SG",
			continent: "Asia",
		},
		{
			country: "Slovakia",
			dial_code: "+421",
			flag: "flags/Slovakia.png",
			code: "SK",
			continent: "Europe",
		},
		{
			country: "Slovenia",
			dial_code: "+386",
			flag: "flags/Slovenia.png",
			code: "SI",
			continent: "Europe",
		},
		{
			country: "Somalia",
			dial_code: "+252",
			flag: "flags/Somalia.png",
			code: "SO",
			continent: "Africa",
		},
		{
			country: "South africa",
			dial_code: "+27",
			flag: "flags/South_Africa.png",
			code: "ZA",
			continent: "Africa",
		},
		{
			country: "Spain",
			dial_code: "+34",
			flag: "flags/Spain.png",
			code: "ES",
			continent: "Europe",
		},
		{
			country: "Sri Lanka",
			dial_code: "+94",
			flag: "flags/Sri_Lanka.png",
			code: "LK",
			continent: "Asia",
		},
		{
			country: "Sudan",
			dial_code: "+249",
			flag: "flags/Sudan.png",
			code: "SD",
			continent: "Africa",
		},
		{
			country: "Suriname",
			dial_code: "+597",
			flag: "flags/Suriname.png",
			code: "SR",
			continent: "South America",
		},
		{
			country: "Svalbard and Jan Mayen",
			dial_code: "+47",
			flag: "flags/Svalbard.png",
			code: "SJ",
			continent: "Europe",
		},
		{
			country: "Swaziland",
			dial_code: "+268",
			flag: "flags/Swaziland.png",
			code: "SZ",
			continent: "Africa",
		},
		{
			country: "Sweden",
			dial_code: "+46",
			flag: "flags/Sweden.png",
			code: "SE",
			continent: "Europe",
		},
		{
			country: "Switzerland",
			dial_code: "+41",
			flag: "flags/Switzerland.png",
			code: "CH",
			continent: "Europe",
		},
		{
			country: "Syrian Arab Republic",
			dial_code: "+963",
			flag: "flags/Syria.png",
			code: "SY",
			continent: "Asia",
		},
		{
			country: "Taiwan",
			dial_code: "+886",
			flag: "flags/Taiwan.png",
			code: "TW",
			continent: "Asia",
		},
		{
			country: "Tajikistan",
			dial_code: "+992",
			flag: "flags/Tajikistan.png",
			code: "TJ",
			continent: "Asia",
		},
		{
			country: "Tanzania, United Republic of Tanzania",
			dial_code: "+255",
			flag: "flags/Tanzania.png",
			code: "TZ",
			continent: "Africa",
		},
		{
			country: "Thailand",
			dial_code: "+66",
			flag: "flags/Thailand.png",
			code: "TH",
			continent: "Asia",
		},
		{
			country: "Timor-Leste",
			dial_code: "+670",
			flag: "flags/Timor-Leste.png",
			code: "TL",
			continent: "Asia",
		},
		{
			country: "Togo",
			dial_code: "+228",
			flag: "flags/Togo.png",
			code: "TG",
			continent: "Africa",
		},
		{
			country: "Tokelau",
			dial_code: "+690",
			flag: "flags/Tokelau.png",
			code: "TK",
			continent: "Oceania",
		},
		{
			country: "Tonga",
			dial_code: "+676",
			flag: "flags/Tonga.png",
			code: "TO",
			continent: "Oceania",
		},
		{
			country: "Trinidad and Tobago",
			dial_code: "+1868",
			flag: "flags/Trinidad_and_Tobago.png",
			code: "TT",
			continent: "North America",
		},
		{
			country: "Tunisia",
			dial_code: "+216",
			flag: "flags/Tunisia.png",
			code: "TN",
			continent: "Africa",
		},
		{
			country: "Turkey",
			dial_code: "+90",
			flag: "flags/Turkey.png",
			code: "TR",
			continent: "Asia",
		},
		{
			country: "Turkmenistan",
			dial_code: "+993",
			flag: "flags/Turkmenistan.png",
			code: "TM",
			continent: "Asia",
		},
		{
			country: "Turks and Caicos Islands",
			dial_code: "+1649",
			flag: "flags/Turks_and_Caicos_Islands.png",
			code: "TC",
			continent: "North America",
		},
		{
			country: "Tuvalu",
			dial_code: "+688",
			flag: "flags/Tuvalu.png",
			code: "TV",
			continent: "Oceania",
		},
		{
			country: "Uganda",
			dial_code: "+256",
			flag: "flags/Uganda.png",
			code: "UG",
			continent: "Africa",
		},
		{
			country: "Ukraine",
			dial_code: "+380",
			flag: "flags/Ukraine.png",
			code: "UA",
			continent: "Europe",
		},
		{
			country: "United Arab Emirates",
			dial_code: "+971",
			flag: "flags/United_Arab_Emirates.png",
			code: "AE",
			continent: "Asia",
		},
		{
			country: "United Kingdom",
			dial_code: "+44",
			flag: "flags/United_Kingdom.png",
			code: "GB",
			continent: "Europe",
		},
		{
			country: "United States",
			dial_code: "+1",
			flag: "flags/United_States_of_America.png",
			code: "US",
			continent: "North America",
		},
		{
			country: "Uruguay",
			dial_code: "+598",
			flag: "flags/Uruguay.png",
			code: "UY",
			continent: "South America",
		},
		{
			country: "Uzbekistan",
			dial_code: "+998",
			flag: "flags/Uzbekistan.png",
			code: "UZ",
			continent: "Asia",
		},
		{
			country: "Vanuatu",
			dial_code: "+678",
			flag: "flags/Vanuatu.png",
			code: "VU",
			continent: "Oceania",
		},
		{
			country: "Venezuela, Bolivarian Republic of Venezuela",
			dial_code: "+58",
			flag: "flags/Venezuela.png",
			code: "VE",
			continent: "South America",
		},
		{
			country: "Vietnam",
			dial_code: "+84",
			flag: "flags/Vietnam.png",
			code: "VN",
			continent: "Asia",
		},
		{
			country: "Virgin Islands, British",
			dial_code: "+1284",
			flag: "flags/Serbia_and_Montenegro.png",
			code: "VG",
			continent: "North America",
		},
		{
			country: "Virgin Islands, U.S.",
			dial_code: "+1340",
			flag: "flags/Virgin_Islands.png",
			code: "VI",
			continent: "North America",
		},
		{
			country: "Wallis and Futuna",
			dial_code: "+681",
			flag: "flags/Wallis_and_Futuna.png",
			code: "WF",
			continent: "Oceania",
		},
		{
			country: "Yemen",
			dial_code: "+967",
			flag: "flags/Yemen.png",
			code: "YE",
			continent: "Asia",
		},
		{
			country: "Yugoslavia",
			dial_code: "+38",
			flag: "flags/Yugoslavia.png",
			code: "YU",
			continent: "Europe",
		},
		{
			country: "Zambia",
			dial_code: "+260",
			flag: "flags/Zambia.png",
			code: "ZM",
			continent: "Africa",
		},
		{
			country: "Zimbabwe",
			dial_code: "+263",
			flag: "flags/Zimbabwe.png",
			code: "ZW",
			continent: "Africa",
		},
	],
};
